<template>
  <div class="total">
    <div class="BulkCrmsCusAddressDetailListLs" v-if="this.bulkCrmsCusAddressDetailList.length<=0">
      <el-empty description="暂无数据"></el-empty>
    </div>
    <div v-else class="BulkCrmsCusAddressDetailList" v-for="(item,index) in this.bulkCrmsCusAddressDetailList">
      <el-card shadow="hover" @click.native="editAddress(index, item)">
        <el-descriptions class="margin-top" title="联系人详情" :column="1" size="medium">
          <template slot="extra">
          </template>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>
              联系人
            </template>
            {{ item.contacts }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-postcard"></i>
              职务
            </template>
            <el-tag size="small">{{ item.contactsPost }}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user-solid"></i>
              负责人
            </template>
            {{ item.createdPeople }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-mobile-phone"></i>
              手机号
            </template>
            {{ item.contactsPhone }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-office-building"></i>
              联系地址
            </template>
            {{ item.addressDetailed }}
          </el-descriptions-item>
        </el-descriptions>
      </el-card>
    </div>
    <!-- 添加按钮-->
    <Add to="/BulkCrmsCusAddressDetailAddLs/" :param=this.$route.params.name></Add>
  </div>
</template>

<script>

import Add from '@/components/Add';

export default {
  name: "BulkCrmsCusAddressDetailListLs",
  inject: ['reload'],
  components: {Add},
  data() {
    return {
      bulkCrmsCusAddressDetailList: [],
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.$ajax.post('/api/ajax/cusAddress/getBulkCrmsLsCusAddressList.json',
          {addressOtherid: this.$route.params.name, createdPeople: this.$store.getters.userId, addressId: ""}).then(res => {
        if (res.status == 200) {
          //非本人添加不允许编辑删除
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].createdPeople == this.$store.getters.userId) {
              res.data[i].type = false;
            } else {
              res.data[i].type = true;
            }
          }
          this.bulkCrmsCusAddressDetailList = res.data.sort()
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('初始化失败！');
      })
    },
    deleteAddress(index, item) {
      this.$ajax.post('/api/ajax/cusAddress/deleteBulkCrmsLsCusAddress.json',
          {id: item.id}).then(res => {
        if (res.status == 200) {
          this.$message({
            showClose: true,
            message: '删除成功',
            type: 'success'
          });
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('删除失败！');
      })
      setTimeout(() => {
        this.init()
      }, 200);
    },
    editAddress(index, item) {
      sessionStorage.setItem('cusId', item.addressId)
      console.log(item)
      this.$router.push({path: '/BulkCrmsCusAddressDetailEditLs/' + item.addressId})
    },
  }
}
</script>

<style scoped>
.total {
  background: white;
}

.BulkCrmsCusAddressDetailList {
  margin: 5px 5px;
  background: white;
}

.detail {
  padding: 5px;
}

.van-button {
  width: 220px;
  height: 35px;
  border-radius: 5px;
  font-weight: bold;
  font-family: 新宋体;
}

</style>
